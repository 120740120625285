<template>
  <div class="register-bg">
    <p class="title">{{ $t('Register.title') }}</p>
    <p class="txt">{{ $t('Register.txt') }}</p>
    <!-- <p class="stu-message" ref="page-top">{{ $t('Register.information') }}</p> -->
    <div class="form" v-show="apparent === '1'" ref="page-1">
      <el-form :label-width="labelWidth" :model="ruleForm1" ref="ruleForm1">
        <el-form-item required :label="$t('Register.email_address')">
          <el-input v-model="ruleForm1.email"></el-input>
        </el-form-item>
        <el-form-item required :label="$t('Register.password')">
          <el-input v-model="ruleForm1.password" type="password"></el-input>
        </el-form-item>
        <el-form-item
          required
          :label="$t('Register.repassword')"
          class="mobile-label"
        >
          <el-input v-model="ruleForm1.repassword" type="password"></el-input>
        </el-form-item>
        <div v-if="$i18n.locale == 'cn'">
          <el-form-item required :label="$t('Register.name_cn')">
          </el-form-item>
          <div class="chinese-name">
            <el-form-item required :label="$t('Register.lastname_cn')">
              <el-input v-model="ruleForm1.lastname_cn"></el-input>
            </el-form-item>
            <el-form-item
              class="firstname-cn"
              required
              :label="$t('Register.firstname_cn')"
            >
              <el-input v-model="ruleForm1.firstname_cn"></el-input>
            </el-form-item>
          </div>
          <el-form-item required :label="$t('Register.name_en')">
          </el-form-item>
          <div class="chinese-name">
            <el-form-item required :label="$t('Register.lastname_en')">
              <el-input v-model="ruleForm1.lastname_en"></el-input>
            </el-form-item>
            <el-form-item
              style="firstname-en"
              required
              :label="$t('Register.firstname_en')"
            >
              <el-input v-model="ruleForm1.firstname_en"></el-input>
            </el-form-item>
          </div>
        </div>
        <div v-else>
          <el-form-item required :label="$t('Register.name_en')">
          </el-form-item>
          <div class="chinese-name">
            <el-form-item required :label="$t('Register.lastname_en')">
              <el-input v-model="ruleForm1.lastname_en"></el-input>
            </el-form-item>
            <el-form-item
              style="firstname-en"
              required
              :label="$t('Register.firstname_en')"
            >
              <el-input v-model="ruleForm1.firstname_en"></el-input>
            </el-form-item>
          </div>
          <el-form-item required :label="$t('Register.name_cn')"> </el-form-item>
          <div class="chinese-name">
            <el-form-item required :label="$t('Register.lastname_cn')">
              <el-input v-model="ruleForm1.lastname_cn"></el-input>
            </el-form-item>
            <el-form-item required 
              class="firstname-cn"
              :label="$t('Register.firstname_cn')"
            >
              <el-input v-model="ruleForm1.firstname_cn"></el-input>
            </el-form-item>
          </div>
        </div>
        <el-form-item required :label="$t('Register.age')">
          <el-select
            v-model="ruleForm1.age_range"
            class="half-width"
            :placeholder="$t('Register.prompt_age')"
          >
            <el-option label="<18" value="1"></el-option>
            <el-option label="18-25" value="2"></el-option>
            <el-option label="26-35" value="3"></el-option>
            <el-option label="36-45" value="4"></el-option>
            <el-option label="46-55" value="5"></el-option>
            <el-option label="56-65" value="6"></el-option>
            <el-option label=">65" value="7"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('Register.bir')">
          <el-date-picker
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            :placeholder="$t('Register.prompt_data')"
            size="large"
            v-model="ruleForm1.birth_of_date"
            class="half-width"
          ></el-date-picker>
        </el-form-item>
        <el-form-item required class="sex" :label="$t('Register.sex')">
          <div>
            <el-radio-group class="sex_group" v-model="ruleForm1.gender">
              <el-radio label="m">{{ $t('Register.sex_m') }}</el-radio>
              <el-radio label="f">{{ $t('Register.sex_w') }}</el-radio>
              <span>
                <el-radio label="o" class="other">
                  {{ $t('Register.sex_other') }}
                </el-radio>
              </span>
            </el-radio-group>
          </div>
        </el-form-item>
        <!--  <el-form-item required :label="$t('Register.area_code')">
          <el-input v-model="ruleForm1.area_code"></el-input>
        </el-form-item> -->
        <el-form-item
          required
          :label="$t('Register.mobile')"
          class="mobile-label"
        >
          <el-input type="text" v-model="ruleForm1.mobile">
            <template slot="prepend"> +852 </template>
          </el-input>
        </el-form-item>
        <el-form-item :label="$t('Register.address')">
          <el-input v-model="ruleForm1.address"></el-input><br />
          <!-- <span class="address-tips">{{ $t('Register.confirm_address') }}</span> -->
        </el-form-item>
      </el-form>
      <div class="btn">
        <el-button @click="nextPage" type="primary">
          {{ $t('Register.continue') }}
        </el-button>
      </div>
    </div>
    <div
      v-loading="loadingRegister"
      class="form2"
      v-show="apparent === '2'"
      ref="page-2"
    >
      <el-form :model="ruleForm1" ref="ruleForm1" label-width="">
        <el-form-item
          required
          class="identity"
          :label="$t('Register.identity')"
        >
          <br />
          <el-checkbox-group v-model="ruleForm1.capacity">
            <el-checkbox label="sports_coach" name="type">{{
              $t('Register.identity_coach')
            }}</el-checkbox>
            <el-checkbox label="physical_education_teacher" name="type">{{
              $t('Register.identity_teacher')
            }}</el-checkbox>
            <el-checkbox label="social_worker" name="type">{{
              $t('Register.identity_social')
            }}</el-checkbox>
            <el-checkbox label="youth_worker" name="type">{{
              $t('Register.identity_yang')
            }}</el-checkbox>
            <el-checkbox label="parent" name="type">
              {{ $t('Register.identity_parents') }}
            </el-checkbox>
            <el-checkbox label="student" name="type">
              {{ $t('Register.identity_stu') }}
            </el-checkbox>
            <el-checkbox label="other" name="type" class="identity other-label">
              <span>{{ $t('Register.edu_other') }}</span>
              <el-input
                class="other"
                size="mini"
                v-model="ruleForm1.other_capacity"
                :placeholder="$t('Register.prompt')"
              ></el-input>
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>

        <el-form-item
          required
          class="sex"
          :label="$t('Register.education_level')"
        >
          <div class="education">
            <el-radio-group
              class="sex_group"
              v-model="ruleForm1.education_level"
            >
              <el-radio label="primary_or_below">{{
                $t('Register.edu_primary')
              }}</el-radio>
              <el-radio label="secondary">{{
                $t('Register.edu_secondary')
              }}</el-radio>
              <el-radio label="higher_diploma">{{
                $t('Register.edu_higher_diploma')
              }}</el-radio>
              <el-radio label="bachelor">{{
                $t('Register.edu_bachelor')
              }}</el-radio>
              <el-radio label="master">{{
                $t('Register.edu_master')
              }}</el-radio>
              <el-radio label="doctor">{{
                $t('Register.edu_doctor')
              }}</el-radio>
              <el-radio label="other" class="other-label"
                >{{ $t('Register.edu_other') }}
                <el-input
                  size="mini"
                  v-model="ruleForm1.other_education_level"
                  :placeholder="$t('Register.prompt')"
                ></el-input>
              </el-radio>
              <span> </span>
            </el-radio-group>
          </div>
        </el-form-item>
        <el-form-item
          required
          class="identity"
          :label="$t('Register.main_clients')"
        >
          <el-checkbox-group v-model="ruleForm1.main_clients">
            <el-checkbox label="kindergarten" name="type">{{
              $t('Register.kindergarten')
            }}</el-checkbox>
            <el-checkbox label="primary" name="type">{{
              $t('Register.primary_student')
            }}</el-checkbox>
            <el-checkbox label="secondary" name="type">{{
              $t('Register.secondary_student')
            }}</el-checkbox>
            <el-checkbox label="university" name="type">{{
              $t('Register.university')
            }}</el-checkbox>
            <el-checkbox label="adult" name="type">{{
              $t('Register.adult')
            }}</el-checkbox>
            <el-checkbox label="elderly" name="type">{{
              $t('Register.elderly')
            }}</el-checkbox>
            <el-checkbox label="not_applicable" name="type">{{
              $t('Register.not_applicable')
            }}</el-checkbox>
            <el-checkbox label="other" name="type" class="identity other-label">
              <span>{{ $t('Register.edu_other') }}</span>
              <el-input
                class="other"
                size="mini"
                v-model="ruleForm1.other_min_client"
                :placeholder="$t('Register.prompt')"
              ></el-input>
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item
          class="identity"
          :label="$t('Register.interested_content')"
        >
          <br />
          <el-checkbox-group v-model="ruleForm1.interested_content">
            <el-checkbox label="e_learning_unit" name="type">{{
              $t('Register.e_learning_unit')
            }}</el-checkbox>
            <el-checkbox label="sports_demonstration_film" name="type">{{
              $t('Register.sports_demonstration_film')
            }}</el-checkbox>
            <el-checkbox label="safeguarding" name="type">{{
              $t('Register.interested_safeguarding')
            }}</el-checkbox>
            <el-checkbox label="coach_connection" name="type">{{
              $t('Register.interested_coach_connection')
            }}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item>
          <span class="ques">{{ $t('Register.question1') }}</span>
          <el-input :rows="10" type="textarea" v-model="ruleForm1.apply_reason">
          </el-input>
        </el-form-item>
        <div class="checkbox-ground">
          <el-form-item
            required
            class="ques2"
            :label="$t('Register.question2')"
          >
            <!-- {{ $t('Register.question2') }} -->
            <el-checkbox-group v-model="ruleForm1.way">
              <el-checkbox label="website" name="type">{{
                $t('Register.way_website')
              }}</el-checkbox>
              <el-checkbox label="facebook" name="type">{{
                $t('Register.way_facebook')
              }}</el-checkbox>
              <el-checkbox label="instagram" name="type">{{
                $t('Register.way_instagram')
              }}</el-checkbox>
              <el-checkbox label="newsletter" name="type">{{
                $t('Register.way_newsletter')
              }}</el-checkbox>
              <el-checkbox label="staff" name="type">{{
                $t('Register.way_staff')
              }}</el-checkbox>
              <el-checkbox label="whatsapp" name="type">{{
                $t('Register.way_whatsapp')
              }}</el-checkbox>
              <el-checkbox label="friends" name="type">{{
                $t('Register.way_friends')
              }}</el-checkbox>
              <el-checkbox label="poster" name="type">{{
                $t('Register.way_poster')
              }}</el-checkbox>
              <el-checkbox label="other" name="type" class="other-label">
                <span>{{ $t('Register.way_other') }}</span>
                <el-input
                  size="mini"
                  v-model="ruleForm1.other_way"
                  :placeholder="$t('Register.prompt')"
                ></el-input>
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <div class="declare">
            <span>{{ $t('Register.declare1') }}</span
            ><br /><br />
            <span>{{ $t('Register.declare2') }}</span
            ><br /><br />
            <span>{{ $t('Register.declare3') }}</span
            ><br /><br />
          </div>
          <div class="agree" style="margin-bottom: 15px">
            <el-checkbox
              name="type"
              v-model="ruleForm1.notSubscribe"
              label="subscribe"
            >
              <span>{{ $t('Register.subscribe') }}</span>
            </el-checkbox>
          </div>
          <div class="agree">
            <el-checkbox name="type" @change="agreePolicy">
              <span>{{ $t('Register.agree') }}</span>
            </el-checkbox>
          </div>
        </div>
      </el-form>
      <div class="btn">
        <el-button @click="returnon" type="primary">
          {{ $t('Register.return') }}
        </el-button>
        <el-button
          type="primary"
          :disabled="btnChangeEnable"
          @click="submitUserInfo"
          >{{ $t('Register.commit') }}
        </el-button>
      </div>
    </div>
    <div class="page-item">{{ $t('Register.page') + apparent + '/2' }}</div>
  </div>
</template>
<script>
import { registerUser, loginUser } from '@/api/memberUser'
export default {
  data () {
    return {
      screenWidth: window.document.body.clientWidth,
      ruleForm1: {
        email: '',
        password: '',
        repassword: '',
        firstname_en: '',
        lastname_en: '',
        firstname_cn: '',
        lastname_cn: '',
        name_en: '',
        name_zh_tw: '',
        age_range: '',
        birth_of_date: null,
        gender: '',
        other_gender: '',
        //area_code: "+852",
        mobile: '',

        address: '',
        capacity: [],
        other_capacity: '',
        apply_reason: '',
        way: [],
        other_way: '',
        interested_content: [],
        education_level: '',
        other_education_level: '',
        main_clients: [],
        other_min_client: '',
        notSubscribe: false,
        subscribed: true
      },
      agreeTxt: '',
      input: '',
      active: 0,
      apparent: '1',
      btnChangeEnable: true,
      loading: true,
      loadingRegister: false
    }
  },
  computed: {
    labelWidth () {
      const lang = window.localStorage.getItem('locale')
      return lang === 'en' && this.screenWidth > 480
        ? '170px'
        : lang === 'cn' && this.screenWidth > 480
        ? '120px'
        : lang === 'en' && this.screenWidth <= 480
        ? '128px'
        : '105px'
    }
  },
  methods: {
    agreePolicy () {
      this.btnChangeEnable = !this.btnChangeEnable
    },
    nextPage () {
      let isFull =
        this.ruleForm1.email === '' ||
        this.ruleForm1.password === '' ||
        this.ruleForm1.repassword === '' ||
        this.ruleForm1.age_range === '' ||
        (this.ruleForm1.gender === '' && this.ruleForm1.other_gender === '') ||
        //this.ruleForm1.area_code === "" ||
        this.ruleForm1.mobile === ''

      let isName = true
      let regEng = new RegExp('^[A-Za-z\\s]+$')
      let regChi = new RegExp('[A-Za-z0-9\\s]+')
      let regPhone = new RegExp('^[0-9]*$')
      if (this.$i18n.locale == 'cn') {
        if (
          !this.ruleForm1.lastname_cn ||
          !this.ruleForm1.firstname_cn ||
          !this.ruleForm1.firstname_en ||
          !this.ruleForm1.lastname_en
        ) {
          isName = false
        } else {
          if (regChi.test(this.ruleForm1.lastname_cn)) {
            isName = false
          }
          if (regChi.test(this.ruleForm1.firstname_cn)) {
            isName = false
          }
          if (!regEng.test(this.ruleForm1.lastname_en)) {
            isName = false
          }
          if (!regEng.test(this.ruleForm1.firstname_en)) {
            isName = false
          }
        }
      } else {
        if (!this.ruleForm1.firstname_en || !this.ruleForm1.lastname_en) {
          isName = false
        } else {
          if (
            this.ruleForm1.lastname_cn &&
            regChi.test(this.ruleForm1.lastname_cn)
          ) {
            isName = false
          }
          if (
            this.ruleForm1.firstname_cn &&
            regChi.test(this.ruleForm1.firstname_cn)
          ) {
            isName = false
          }
          if (!regEng.test(this.ruleForm1.lastname_en)) {
            isName = false
          }
          if (!regEng.test(this.ruleForm1.firstname_en)) {
            isName = false
          }
        }
      }
      if (!isName) {
        this.$message({
          message: this.$t('Register.error_name'),
          type: 'error'
        })
        return
      }
      if (isFull) {
        this.$message({
          message: '請填寫完整',
          type: 'error'
        })
        return
      }
      var reg = new RegExp(
        /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/
      )
      if (!reg.test(this.ruleForm1.email)) {
        this.$message({
          message: 'Email is invalid',
          type: 'error'
        })
        return
      }
      if (this.ruleForm1.password.length < 6) {
        this.$message({
          message: '密碼不少於6位',
          type: 'error'
        })
        return
      } else if (this.ruleForm1.password !== this.ruleForm1.repassword) {
        this.$message({
          message: '兩次輸入密碼不一致',
          type: 'error'
        })
        return
      }
      if (
        !regPhone.test(this.ruleForm1.mobile.replace(/ /g, '')) ||
        this.ruleForm1.mobile.replace(/ /g, '').length != 8
      ) {
        this.$message({
          message: '電話號碼無效',
          type: 'error'
        })
        return
      }
      this.apparent = '2'
      this.$refs['page-top'].scrollIntoView()
    },
    submitUserInfo () {
      let isFull =
        (this.ruleForm1.capacity.length < 1 &&
          this.ruleForm1.other_capacity === '') ||
        (this.ruleForm1.way.length < 1 && this.ruleForm1.other_way === '') ||
        (this.ruleForm1.education_level === '' &&
          this.ruleForm1.other_education_level === '') ||
        (this.ruleForm1.main_clients.length < 1 &&
          this.ruleForm1.other_main_clients === '')
      if (isFull) {
        this.$message({
          message: '請填寫完整',
          type: 'error'
        })
        return
      }
      this.ruleForm1.subscribed = !this.ruleForm1.notSubscribe
      this.ruleForm1.name_en =
        this.ruleForm1.firstname_en + ' ' + this.ruleForm1.lastname_en
      this.ruleForm1.name_zh_tw =
        this.ruleForm1.lastname_cn + this.ruleForm1.firstname_cn
      if (!this.ruleForm1.name_zh_tw) {
        this.ruleForm1.name_zh_tw = '[EMPTY]'
      }
      if (!this.ruleForm1.address) {
        this.ruleForm1.address = '[EMPTY]'
      }
      this.loadingRegister = true
      registerUser(this.ruleForm1)
        .then(async res => {
          if (res.result === 'success') {
            const data = {
              username: this.ruleForm1.email,
              password: this.ruleForm1.repassword
            }
            let loginRes = await loginUser(data)
            if (loginRes.result == 'success') {
              window.sessionStorage.setItem('token', loginRes.data.access_token)
              this.$message({
                message: this.$t('Register.success'),
                type: 'success'
              })
              await new Promise(resolve =>
                setTimeout(() => {
                  this.loadingRegister = false
                  resolve(this.$router.push({ name: 'Inspire' }))
                }, 1500)
              )
              location.reload()
            }
          } else {
            this.loadingRegister = false
            this.$router.push({ name: 'Inspire' })
          }
        })
        .catch(error => {
          console.log(error)
          this.$message({
            message: error.response.data.error.detail,
            type: 'error'
          })
          this.loadingRegister = false
        })
    },
    returnon () {
      this.apparent = '1'
      this.$refs['page-top'].scrollIntoView()
    }
  },
  created () {
    if ('email' in this.$route.query) {
      this.ruleForm1.email = this.$route.query.email
    }
  },
  watch: {
    ['ruleForm1.mobile'] (val) {
      this.$nextTick(() => {
        this.ruleForm1.mobile = val
          .replace(/\s/g, '')
          .replace(/....(?!$)/g, '$& ')
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.el-button,
.el-button:focus,
.el-button:active {
  // background-color: #28327b;
  background-color: #399efc;
}
.el-button:hover {
  background-color: rgba(40, 50, 123, 0.75);
}
button {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
.declare {
  font-size: 16px;
}
.register-bg {
  letter-spacing: 0px;
  width: 70%;
  margin: 0 auto;
  .title {
    font-size: 32px;
    font-weight: bold;
    color: #93ca25;
  }
  .txt {
    font-size: 18px;
    margin-top: 10px;
        margin-bottom: 50px;

  }
  .stu-message {
    text-align: center;
    margin: 20px 0 30px 0;
    font-size: 28px;
  }
  ::v-deep .el-form-item {
    text-align: left;
    .el-form-item__label {
      color: #333;
      font-size: 16px;
      padding-right: 30px;
    }
    .el-input__inner {
      font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    }
  }
  .el-form-item {
    text-align: left;
    margin-bottom: 18px;
    .el-form-item__label {
      color: #333;
    }
  }
  .form {
    .chinese-name {
      display: flex;
      width: 100%;
      align-items: stretch !important;
      .el-form-item {
        flex: 1;
        text-align: left;
        .el-input {
          width: 100%;
        }
        .el-form-item__label {
          color: #333;
        }
      }
    }
    .address-tips {
      display: block;
      line-height: 42px;
      font-size: 20px;
      margin-top: 20px;
    }
    .age {
      text-align: left;
      .el-select {
        width: 100%;
      }
    }
    .sex {
      text-align: left;
      .sex_group {
        text-align: left;
        .other {
          .input {
            width: 90%;
          }
        }
      }
    }
    .identity {
      text-align: left;
      .other {
        width: 86%;
        margin-left: 2%;
      }
    }
  }
  .form2 {
    margin-left: 12%;
    width: 70%;
    text-align: left;
    .other {
      width: 35%;
    }
    .el-form-item {
      .el-input {
        width: 60%;
        margin-left: 5px;
      }
      .el-form-item__content {
        .education {
          .el-radio {
            display: flex;
            align-items: center;
            margin-top: 15px;
          }
        }
      }
    }
    .agree {
      ::v-deep .el-checkbox__input {
        margin-top: 3px;
      }
      ::v-deep .el-checkbox {
        display: flex;
        white-space: unset;
      }
    }
  }
  .btn {
    margin-top: 50px;
    text-align: center;
    ::v-deep .el-button {
      font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    }
  }
  .half-width {
    width: calc(50% - 79px);
  }
  .page-item {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 0 50px;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 480px) {
  .declare {
    font-size: 26px;
  }
  .register-bg {
    width: 100%;
    letter-spacing: 0px;
    margin: 0px;
    .title,
    .stu-message {
      font-size: 32px;
    }
    .txt {
      padding: 0 40px;
    }
    .form {
      padding: 0 40px;
      .el-col-11 {
        width: 60%;
      }
      ::v-deep .el-radio {
        padding: 10px 0;
      }
      .chinese-name {
        display: flex;
        flex-direction: column;
        align-items: stretch !important;
        .el-form-item {
          text-align: left;
          .el-form-item__label {
            color: #333;
          }
        }
      }
    }
    .form2 {
      width: 86%;
      margin: 0 auto;

      .ques {
        display: inline-block;
        text-align: left;
      }
      .agree {
        .el-checkbox {
          display: flex;
          ::v-deep .el-checkbox__input {
            padding-top: 5px;
          }
        }
      }
      .sex_group {
        margin-bottom: 10px;
      }
    }
    .half-width {
      width: 100%;
    }
  }
  ::v-deep .other-label {
    display: flex !important;
    align-items: flex-start !important;
    width: 100%;
    .el-checkbox__input,
    .el-radio__input {
      margin-top: 5px;
    }
    .el-checkbox__label,
    .el-radio__label {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      width: 100%;
      span {
        display: block;
        flex: 1;
      }
      .el-input {
        width: 100% !important;
        margin-top: 20px;
      }
    }
  }
  ::v-deep .mobile-label {
    .el-form-item__label {
      line-height: 1.3;
    }
  }
}
</style>
